const Privacy = () => {
    return (
        <main>
            <h1>Privacy Policy for CodeLearn App</h1>

            <p>Last Updated: August 15, 2023</p>

            <p>Welcome to LearnDS! This Privacy Policy outlines how CodeLearn ("we," "our," or "us") collects, uses, and safeguards
                your personal information when you use our programming learning application (the "App"). Your privacy is of utmost
                importance to us, and we are committed to protecting your personal data. By using the App, you agree to the terms
                described in this Privacy Policy.</p>

            <h2>Information We Collect</h2>

            <ol>
                <li><strong>Personal Information:</strong> We may collect personal information such as your name, email address,
                    and other contact information when you create an account on our App.</li>

                <li><strong>Usage Data:</strong> We automatically collect information about how you use the App, including your
                    interactions, progress, and performance within programming lessons and exercises.</li>

                <li><strong>Device Information:</strong> We may gather information about the device you use to access the App,
                    such as device type, operating system, browser type, and unique device identifiers.</li>
            </ol>

            <h2>How We Use Your Information</h2>

            <ol>
                <li><strong>Providing Services:</strong> We use your information to create and manage your account,
                    deliver personalized programming lessons, track your progress, and provide technical support.</li>

                <li><strong>Improving the App:</strong> We analyze usage patterns and engagement data to enhance the App's
                    content, features, and user experience.</li>

                <li><strong>Communication:</strong> We may send you emails related to your account, such as password resets,
                    important updates, and promotional materials. You can opt out of promotional emails at any time.</li>

                <li><strong>Anonymized Data:</strong> We may use anonymized and aggregated data for research, statistical
                    analysis, and to improve our offerings. This data does not identify you personally.</li>
            </ol>

            <p>If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at learnds-support@gmail.com.</p>

            <p>By using LearnDS, you acknowledge that you have read and understood this Privacy Policy and agree to the
                collection and use of your information as described herein.</p>
        </main>
    )
}

export default Privacy