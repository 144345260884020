import { logEvent } from "firebase/analytics";
import { analytics } from './components/signing/config';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Privacy from './pages/Privacy';

function App() {
  /**@later enable this later*/
  /*
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/sw.js', { scope: '/' })
        .then((registration) => console.log('scope is: ', registration.scope));
  }
  */

  /**@later finish analytics*/
  logEvent(analytics, 'notification_received');
  //console.log(analytics);

  
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/privacy_policy' element={<Privacy />} />
      </Routes>
    </Router>
  );
}

export default App;
