import { signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from 'react';
import { auth, provider, database } from './config';
import { ref, set, onValue } from "firebase/database";

function PopUp() {
    const [user, setUser] = useState(null);
    const [readData, setReadData] = useState(null);

    /**@todo clean signin logs*/
    /**@todo test db rules -- already tested and working*/
    /**@todo use private route for dashboard, etc*/
    /**@later change public email in popup*/
    /**@later enable recaptcha bot test*/
    const handleGoogleSignIn = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                //const credential = GoogleAuthProvider.credentialFromResult(result);
                //const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // IdP data available using getAdditionalUserInfo(result)
                // ...
                setUser(user);
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('signin errorMessage: ' + errorMessage + errorCode);
                // The email of the user's account used.
                //const email = error.customData.email;
                // The AuthCredential type that was used.
                //const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            setUser(null);
            console.log('user has logged out');
        }).catch((error) => {
            const errorMessage = error.message;
            console.log('logout error: ' + errorMessage);
        });
    }

    useEffect(() => {
        // Listen for auth state changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                console.log('auth changed is logged');
                console.log('auth changed is anonym: ' + user.isAnonymous);

                const dbRef = ref(database, 'users/' + user.uid);
                onValue(dbRef, (snapshot) => {
                    const dataVal = snapshot.child("lala").val();
                    // Update the state with the data
                    setReadData(dataVal);
                    console.log(dataVal);
                });
            } else {
                setUser(null);
            }
        });

        // Unsubscribe when the component unmounts
        return () => unsubscribe();
    }, []);

    const writeUserData = () => {
        set(ref(database, 'users/' + user.uid), {
            lala: "lalals",
            email: user.email,
            profile_picture: user.photoURL
        }).catch((error) => {
            console.error('writing db error:' + error);
        });
    }

    return (
        <div>
            {user ? (
                <div>
                    <button onClick={handleLogout}>LOGOUT</button>
                    <p>{user.email}</p>
                    <div className='photo'>
                        <img src={user.photoURL} alt="dp" referrerPolicy='no-referrer' />
                    </div>

                    <button onClick={writeUserData}>Write Data Base</button>
                </div>
            ) : (
                <button onClick={handleGoogleSignIn}>Sign In With Google</button>
            )}

            {readData ? (
                <div>
                    <h3>Welcome come come to me {readData}</h3>
                </div>
            ) : (
                <p>Loading data...</p>
            )}
        </div>
    );
}
export default PopUp;