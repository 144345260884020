import '../App.css'
import logo from '../logo.svg';
import PopUp from '../components/signing/popup';

const Home = () => {
  let result;
  if ('getDigitalGoodsService' in window) {
    // Digital Goods API is supported!
    result = 'Google Play Billing is supported!';
  } else {
    result = 'DigitalGoodsService is not available.';
    // Use another payment method
  }

    return (
        <div className="App">
            <header className="App-header">
                <PopUp />
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Edit <code>src/App.js</code> and save to reload.
                </p>
                <p>{result}</p>
            </header>
        </div>
    )
}

export default Home